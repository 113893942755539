/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
}
*{
  font-family: 'Poppins', sans-serif ;
  box-sizing: border-box;
}
a{text-decoration: none;}
.mat-h2, .mat-title, .mat-typography h2{
  font-family: 'Poppins', sans-serif ;
}

.mat-drawer-container, .mat-toolbar {
  background-color: #ffffff !important;
}
.d-flex {
  display: flex;
  justify-content: space-between;
}
.half-width {
  width: 48% !important;
}
.full-width {
  width: 100% !important;
}
.ml-5 {
  margin-left: 5%;
}
.mr-5 {
  margin-right: 5%;
}
.flex-column {
  flex-direction: column;
}

.align-items-center {
  align-items: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-end {
  justify-content: flex-end;
}

@keyframes slide {
  100% {
    top: 4%;
  }
}

@keyframes slideComment {
  100% {
    top: 10%;
  }
}

.dialog-bottom-panel {
  position: fixed !important;
  top: 90%;
  height: 96%;
  animation: slide 0.3s forwards;

  .mat-dialog-container {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    overflow: hidden;
    padding-right: 0px;
  }
}

.comment-panel {
  position: fixed !important;
  top: 90%;
  height: 90%;
  animation: slideComment 0.3s forwards;

  .mat-dialog-container {
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}


.mat-dialog-container {
  box-shadow: none;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  margin: auto;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
  z-index: 100;
}

.overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  overflow: hidden;
  cursor: pointer;
}

.disable-text-selection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

html,
body {
  height: 100%;
}


::ng-deep .mat-form-field {
  border: 1px solid #9c9ca8;
  border-radius: 5px;
  color: #9c9ca8;
  background-color: #fff;
}




.performance-indicators-list {
  width: 100%;
  outline: none;
  border: 0px solid #9c9ca8;
  border-radius: 15px;
  color: #88778c;
  background-color: #e5e5e5;
  padding: 10px 15px;
  .ul-div {
      display: flex;
      align-items: center;
      margin-left: 26px;
      padding-bottom: 7px;
      li {
          width: 100%;
          // list-style: none;
          display: flex;
          align-items: center;
          textarea {
              border: none;
              outline: none;
              width: 100%;
              resize: none;
              background-color: transparent;
          }
      }
  }

  .performance-indicators-addBtnDiv {
      display: flex;
      justify-content: end;
      position: relative;
      right: 1%;
      top: 7%;
      height: 10px;
  }
  mat-icon {
      font-size: 18px;
      font-size: 18px;
      margin-left: 20px;
      cursor: pointer;
      display: flex;
      align-items: center;
  }
}

.removebtn-performance {
      color: red;
      border: 1px solid;
      border-radius: 50%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
      background-color: transparent;
      margin-left: 0;
  }
  .removebtn-performance-error {
      color: white;
      border: 1px solid white;
      border-radius: 50%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
      background-color: #f55;
      margin-left: 0;
  }

.removebtn-roles {
  color: red;
  border: 1px solid;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  background-color: #fff;
  margin-left: 0;
}
.removebtn-roles-error {
  color: white;
  border: 1px solid white;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  background-color: #f55;
  margin-left: 0;
}
.add-btn-performance {
  display: flex;
}

.performance-indicators-addBtnDiv {
  display: flex;
  justify-content: end;
  position: relative;
  right: 1%;
  top: 7%;
  height: 10px;
}

.ul-div {
  display: flex;
  align-items: center;
  margin-left: 26px;
  padding-bottom: 7px;
  li {
      width: 100%;
      // list-style: none;
      display: flex;
      align-items: center;
      textarea {
          border: none;
          outline: none;
          width: 100%;
          resize: none;

      }
  }
}

.roles-responsibilities-list {
  width: 100%;
  background-color: #fff;
  border:0;
  border-radius: 15px;
  box-shadow: none;

  mat-icon {
      font-size: 18px;
      font-size: 18px;
      margin-left: 20px;
      cursor: pointer;
      display: flex;
      align-items: center;
  }
}
.margin-bottom-div {
  margin-bottom: 15px;
}

.additional-columns {
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  textarea {
      // height: 50px;
      margin-top: 12px;
      padding: 1%;
      width: 100%;
  }
  hr {
      opacity: 0.4;
      margin-top: 10px;
  }
}
.delete-column-btn-div {
  text-align: end;
  mat-icon {
      color: red;
      font-size: 20px;
      margin-top: 5px;
      cursor: pointer;
  }
}

.errorDynamic-keyParameters {
  color: red;
  font-size: 17px;
}
.errorInputFields {
  color: red;
  border: 2px solid red;
  box-shadow: none;
  border-radius:16px;
}
.errorInputFields-list {
  color: red;
  border: 1px solid red;
  margin-top: -2%;
}
.errorDynamic-addFields {
  color: red;
  border: 2px solid red;
  box-shadow: none;
  border-radius:16px;
}

:host ::ng-deep .createdJd .mat-form-field .mat-form-field-flex {
  border: 1px solid #9c9ca8;
  border-radius: 5px;
  box-shadow: -1px -1px 0px 0px black;
  color: #9c9ca8;
  background-color: #fff;
  // margin-top: 10px;
}
:host ::ng-deep .additionalQualifications .mat-form-field .mat-form-field-flex {
  background-color: #f0f0f0;
}
:host ::ng-deep .createdJd .mat-form-field .mat-input-element {
  opacity: 0.9;
}
:host ::ng-deep .createdJd mat-label {
  color: #021e4b;
  letter-spacing: 0.14px;
  margin: 7px 10px 7px;
  width: 30%;
}
::ng-deep .createdJd .mat-form-field-wrapper {
  padding-bottom: 0 !important;
}
.add-btn2 {
    background: #69287e 0% 0% no-repeat padding-box !important;
    color: #ffffff !important;
    border-radius: 14px !important;
    letter-spacing: 0.13px !important;
    margin-left: 20px !important;
    margin-right: 0 !important;
    width: 160px ;
    font-size: 11px;
    font-weight: 400;
    line-height: 35px !important;
}

::ng-deep .mat-header-cell {

    border: 1px solid black;
    justify-content: center;
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.14px;
}

.mat-header-cell{
    color: black !important;
    font-size: 16px;
    border: none;
}
.mat-table{
    background: transparent;
}
.mat-header-row {
    border: none !important;
    min-height: 40px;
}
.main-wrap,.main-wrapper, 
.main-wrap-industry, 
.main-wrap-family, 
.main-wrap-level,
.main-wrap-title {
  border-radius: 30px !important;
}
.mat-cell{
  border: 0px !important;
  color:#767676;
  font-weight: 500;
}
.mat-header-cell{
  border: 0px !important;
    font-size: 19px !important;
    border-bottom: 1px solid rgba(153, 153, 153, 0.2) !important;
}
.mat-table{
  box-shadow: none !important;
}
/*mat-table{
   box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%) !important;
}
*/

.mat-action-icon{
  color: #938e8e !important;
}
.mat-dialog-container{
  border-radius: 35px !important;
}
/*.cdk-overlay-pane{
    height: 450px;
}*/
.invite-form{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

/*.form-record{
  width: 65%;
}
.mat-form-field-infix{
  padding: 0px !important;
}
.mat-typography mat-form-field .mat-form-field-flex {
  height: 41px;
  background-color: #e9e9e9 !important;
}
.mat-dialog-container
{
  color: rgb(0, 0, 0);
}
*/

.mat-chip-list-wrapper{
  margin: -8px !important;  /*enter email*/
}
mat-sidenav-content{
  overflow-x: hidden !important;
}
.button-close{
  background-color: #fff !important;
  border-radius: 0 !important;
  font-size: smaller !important;
}
.mat-dialog-container{
  background-color: #fff !important;
}

/*.mat-form-field.mat-form-field-invalid .mat-input-element {
    height: 36px;
    background-color: #ddd;
    border-radius: 14px;
    } *//*industry management dialoge*/

.mat-slide-toggle-bar {
  width: 48px !important;
}


.mat-badge-warn .mat-badge-content{
  color: #69287e;
  background: #fff;
  box-shadow:0px 3px 7px 3px rgb(0 0 0 / 8%);
}
.mat-action-icon {
  color: #938e8e;
  margin-right: 16px;
  font-size: 18.5px;
  cursor: pointer;
  transition: .3s all ease-in;
  &:hover{
    color: #69287e !important;
  }
}
.sub-header-div {
  display: flex;
  justify-content: flex-end;
  align-items: self-start;
  .mat-form-field-flex {
    border: 0px solid #021e4b2d;
    border-radius: 13px;
    height: 35px;
    align-items: center !important;
    background-color: #e9e9e9;
  }
  .mat-form-field-flex > .mat-form-field-infix { 
    padding: 0px 8px 0 15px !important;
    min-height: 24px;
  }
  h3 {
    font-size: 18px;
    color: #021e4b;
    letter-spacing: 0.18px;
    font-weight: 500;
  }
}
.search-box2{
  display: flex;
  justify-content: flex-end;
  align-items: self-start;
  margin: 20px 0 5px;
  .mat-form-field-flex {
    border: 0px solid #021e4b2d;
    border-radius: 30px;
    height: 45px;
    align-items: center !important;
    background-color: #ffff;
    box-shadow: 0px 11px 26px 16px rgb(0 0 0 / 3%);
  }
  .mat-form-field-flex > .mat-form-field-infix { 
    padding: 0px 8px 0 22px !important;
    min-height: 24px;
  }
  .mat-form-field{
    width: 295px !important;
 }

}
.sidenav-container{
  background-image: url('../src/assets/images/dash-bg1.png');
  background-repeat: no-repeat;
  background-position: right 100px;
  height: 100%;
  width: 100%;
  background-size: 54%;
  background-position: right bottom 50px;
}
.main-wrap {
  background:rgb(255 255 255 / 34%);
  border-radius: 30px;
  padding: 2%;
  box-shadow:0px 10px 14px 8px rgb(0 0 0 / 6%);
}

.mat-cell,
.mat-header-cell {
    word-wrap: initial;
    display: table-cell;
    text-align: left;
    border: 1px solid #c7c7c7;
    padding-left: 15px;
    line-break: unset;
    width: 100%;
    vertical-align: middle;
    border: none;
}
.mat-cell {
    border-bottom: 1px solid rgb(153, 153, 153, 0.2) !important;
}
.mat-row,
.mat-header-row {
    display: table-row;
}
.mat-row:hover {
    background-color: #f7f7f7;
}

.mat-row{
    min-height: 40px;
}
.profile-menu{
  border-radius: 10px !important;
  padding: 2%;
  box-shadow: 0px 5px 8px 4px rgb(0 0 0 / 5%);
  button.mat-menu-item{
    border-bottom:1px solid #f4f3f3;
  }
  .material-icons {
    font-size: 20px;
  }
}

.mat-dialog-container{
  .social-card {
    display: inline-block !important;
    margin: 10px;
    background: #efefef;
    padding: 8px 20px;
    border-radius: 12px;
  }
}

mat-table {
  overflow-x: auto;
  width: 100%;
}
mat-table {
  box-shadow: none;
  margin-bottom: 10px;
}
mat-header-row {
  border: none;
}

.mat-paginator {
  background-color: transparent;
}

.mat-select-value {
  overflow: visible !important;
  text-align: center;

}

.mat-cell:last-of-type{
  white-space: nowrap;
}
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #69287e;
}

.saveBtn{
    background: #69287e;
    color: #ffffff !important;
    border-radius: 14px !important;
    margin:0 10px !important;
    .mat-icon{
      margin-right: 6px;
    }
}




@media only screen and (max-width:768px) {
  mat-table {
    overflow-x: auto;
 }


}
@media only screen and (max-width: 576px) {
  .search-form .mat-form-field{
      width: 100%;
  }
  .sub-header-div {
      display: block;
      margin-bottom: 0%;
  }
  .sub-header-div h3 {
      font-size: 16px;
  }
  .add-btn2 {
    float: right;
  }

}


@media only screen and (min-width: 426px) and (max-width: 768px) {
  .mat-column-action {
      flex: 0 17%;
  }
  mat-table {
      overflow-x: auto;
  }
}

@media only screen and (max-width: 425px) {
  .header {
      font-size: 20px;
  }
  .header-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 3%;
      flex-wrap: wrap;
  }
 
  .mat-column-action {
      flex: 0 16%;
  }
  .mat-action-icon {
      margin-right: 10px;
  }
  mat-table {
      overflow-x: auto;
  }
  mat-header-row,
  mat-row {
      width: 200%;
  }
}



.sub-header-div .mat-form-field-infix{
  padding: 0px !important;
  margin-bottom: 4px !important;
  margin-left: 7px;
}


.status{
  font-size: 17px !important;
}
.action{
  font-size: 17px !important;
}

.button-icon {
  margin-right: 3px;
}
.cdk-overlay-dark-backdrop {
  background: rgb(28 26 26 / 32%) !important;
}
.mat-dialog-container {
  background-color: #fff !important;
  box-shadow: 0 8px 32px 0 rgb(0 0 0 / 17%);
  border-radius: 20px !important;
}
.search-form{
  font-size: small !important;
}

.search-box{
  input.mat-input-element {
    margin-bottom: 3px;
}
}

mat-cell:last-of-type, mat-header-cell:last-of-type, mat-footer-cell:last-of-type {

    text-align: center !important;
}
#deletebtn:hover{
  color: red !important;
}

.model-close-icon{
    margin-top: 15px;
    color: #bfbfbf;
}

.mat-form-field-suffix{
  margin-right: 3px;
}

.topMenu{
  li{
    a{
      color: #000000d6;
    }
  }
}